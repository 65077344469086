import { type CurrencyValue } from '@amalia/kernel/monetary/types';

import { type AuthType, type ConnectorAuth } from './auth';
import { type DataConnectorTypes } from './connector-types';

export const DATA_CONNECTOR_DIRECTORY_MATCHABLE_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'externalId',
  'hrisId',
  'language',
  'currency',
] as const;

export type DataConnectorDirectoryMatchableField = (typeof DATA_CONNECTOR_DIRECTORY_MATCHABLE_FIELDS)[number];

// Required fields when synchronizeOnlyToExistantUser is false.
export const DATA_CONNECTOR_REQUIRED_MATCHING_FIELDS_IF_SYNC_ALL = ['firstName', 'lastName', 'email'] as const;
// Required fields when synchronizeOnlyToExistantUser is true.
export const DATA_CONNECTOR_REQUIRED_MATCHING_FIELDS_IF_SYNC_EXISTING = ['email', 'externalId', 'hrisId'] as const;

// FIELDS

export const DATA_CONNECTOR_FIELD_TYPES = [
  'address',
  'boolean',
  'currency',
  'date',
  'datetime',
  'enum',
  'number',
  'percent',
  'string',
  'time',
] as const;

export type DataConnectorFieldType = (typeof DATA_CONNECTOR_FIELD_TYPES)[number];

export interface DataConnectorObjectField {
  name: string;
  label: string;
  type: DataConnectorFieldType;

  enabled?: boolean;
  overrideName?: string;
  relationshipName?: string;
  allowedTypes?: DataConnectorFieldType[];
  format?: string;
  isId?: boolean;
  isName?: boolean;
  unique?: boolean;
  referenceTo?: string[];
  isMandatory?: boolean;
  matchingField?: string;
}

// OBJECT
export enum DataConnectorFilterOperator {
  EQ = 'EQ',
  NEQ = 'NEQ',
  LT = 'LT',
  LTE = 'LTE',
  GT = 'GT',
  GTE = 'GTE',
  CONTAINS = 'CONTAINS',
  HAS_PROPERTY = 'HAS_PROPERTY',
  IN = 'IN',
}

export type DataConnectorFilterNoCode = {
  name: DataConnectorObjectField['name'];
  operator: DataConnectorFilterOperator;
  value: Date | string;
};

export type DataConnectorFilterSql = {
  condition: string;
};

export type DataConnectorObjectFilter = DataConnectorFilterNoCode | DataConnectorFilterSql;

export interface DataConnectorObjectSort {
  name: string;
  direction: 'ASC' | 'DESC';
}

export type RecordContentPropertyType = CurrencyValue | boolean | number | string | null;

export type RecordContent = Record<string, RecordContentPropertyType>;

// RECORD
export interface DataConnectorObjectRecord {
  id?: string;
  object: string;
  content: RecordContent;
  ownerId?: string;
  url?: string;
}

export const FREQUENCY_CRONS = ['0 0 * * *', 'manually'] as const;
export type DataConnectorObjectFrequencyCron = (typeof FREQUENCY_CRONS)[number];

export interface DataConnectorObject {
  externalId?: string;
  label: string;
  name: string;
  alias?: string | null;
  mainDateField?: string;
  maxFetchRecords?: number | null;
  filters?: DataConnectorObjectFilter[];
  sort?: DataConnectorObjectSort;
  fields?: DataConnectorObjectField[];
  relations?: DataConnectorObject[];
  frequencyCron?: DataConnectorObjectFrequencyCron;
  isLinkedToDirectoryOrQuotas?: boolean;
  isLinkedToTeamAssignments?: boolean;
  synchronizeOnlyToExistantUser?: boolean;
  isPrunable?: boolean;
  shouldPurgeBeforeSync?: boolean;
  /* Used for powerBI: DAX Query used to import object records. */
  query?: string;

  /** These two are for a sales hack, it's not correctly implemented and no longer available in the frontend. */
  isLinkedToTeams?: boolean;
  synchronizeOnlyToExistantTeam?: boolean;

  /* Used for powerBI: Field name we use as a facet to paginate on during import. */
  paginationField?: {
    table: string;
    column: string;
  };
}

// SOURCE
export interface DataConnectorSource {
  objects?: DataConnectorObject[];
  externalUrl?: string;
}

// TYPES

// CATEGORIES
export enum DataConnectorCategories {
  USER = 'user',
  DATA = 'data',
}

// AUTH
export interface UserInfo {
  userName?: string;
  displayName?: string;
  email?: string;

  tenantId?: string;
}

// USER
export type DataConnectorUser = {
  id: string;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  createdAt?: string;
  updatedAt?: string;
  primaryTeamId?: string | null;
  archived?: boolean;
  userId?: string;
};

// CONNECTORS
export enum DataConnectorStatus {
  /* Connector is active */
  ACTIVE = 'ACTIVE',
  /* Connector is in error (out of sync) */
  ERROR = 'ERROR',
}

export interface DataConnector {
  id: string;
  auth?: ConnectorAuth | null;
  alias: string | null;

  authType?: AuthType | null;

  source?: DataConnectorSource;
  type: DataConnectorTypes;
  category: DataConnectorCategories;
  status?: DataConnectorStatus;
  updatedAt?: Date;

  // We can store connector objet catalog when not available from api.
  // catalog entries:
  // - keys : objectNames
  // - values: object definitions
  catalog?: unknown;

  /**
   * tenant id in connector system (can be Salesforce organization id, Hubspot portal id, ...)
   */
  tenantId?: string;
}

export interface PatchDataConnectorRequest {
  externalUrl?: string;
  alias?: string;
}

/**
 * We use the name as the object primary key to identify the object in the external data source, but we allow the user to override it on our side.
 * The aliased name is used to name the custom object definitions.
 *
 * This method returns the overridden name.
 */
export const getConnectorObjectName = (dataConnectorObject: Pick<DataConnectorObject, 'alias' | 'name'>) =>
  dataConnectorObject.alias || dataConnectorObject.name;
